const BASE_API_LINK =
  'https://globis-andersbeton.eu.qlikcloud.com/api/v1/automations';
//const BLENDR_API_LINK = 'https://admin.blendr.io/api/v1/automations';

const devTokens = {
  authentication:
    'sREI4AJUqLAoDLXIbNyF859tIr8FtoDAfI5nWOspTxWr8UDIHZsZCD0z48L0YWxc',
  sharedTable:
    'RK96zmrnL59Qhg4AztthxfFUxFaz1QVWVffg4pECmxmsxCviT5cjCjXGMkPQGdzt',
  machinePark:
    's9VhKohyY70TpYfBawt2qxmKsidUMTNze8Qns264v9rbRbwstyLUsqZ6Pree0ZYT',
  sendArticles:
    'VWbnl2M1HuMh5Ha7sr3kXMcZ9CtnooxyrsZjMZ8PEpqb45Cda7URnzbMCJLuOXH6',
};

const devRoutes = {
  auth: `${BASE_API_LINK}/6fd55634-318f-4d90-9831-a28442071c38/actions/execute`,
  sharedTable: `${BASE_API_LINK}/0ffbdc3f-a997-4b07-bc7f-9c7aa13d5cb4/actions/execute`,
  machinePark: `${BASE_API_LINK}/75c55b74-0d68-463a-bffd-0aa6198f36b7/actions/execute`,
  sendArticles: `${BASE_API_LINK}/74b3bebe-44ac-4cb8-b9ff-235d0a949fd0/actions/execute`,
};

const prodTokens = {
  authentication:
    'sREI4AJUqLAoDLXIbNyF859tIr8FtoDAfI5nWOspTxWr8UDIHZsZCD0z48L0YWxc',
  sharedTable:
    '1fYq5CiGpLEREGVGX9YRFDntw80PgmX6nV6dkhusTpkMiMwkkb7MmFDouDbd3UUH',
  machinePark:
    '8D3tAYoTjwGdKkYqSRuSrrsXvy3Z4Wyf38laNqSggj5KFbfo7Hje7ySXTKyFvjrV',
  sendArticles:
    'OhWHpm2a1vPHd1GDqzudsQy0w4TD3ceE9R4XMKy04uX90DZkeEZ0kZEs2gp0vLLG',
};

const prodRoutes = {
  auth: `${BASE_API_LINK}/6fd55634-318f-4d90-9831-a28442071c38/actions/execute`,
  sharedTable: `${BASE_API_LINK}/5a432824-04df-43c9-b9c7-19c63f5c39db/actions/execute`,
  machinePark: `${BASE_API_LINK}/3de540a4-f9d8-4acb-9b46-0f268534dde8/actions/execute`,
  sendArticles: `${BASE_API_LINK}/465a9d4d-3e5f-45f8-b31e-96e72f1daf06/actions/execute`,
};

export { devTokens, devRoutes, prodTokens, prodRoutes };
