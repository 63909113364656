import { getUTCDate } from './getUTCDate';

export const getWorkingDaysInWeeks = (year: number) => {
  const startOfYear = new Date(Date.UTC(year, 0, 1));
  const endOfYear = new Date(Date.UTC(year, 11, 31));

  const startOfTheFirstWeek = getDayTheWeek(startOfYear, 'first');
  const endOfTheLastWeek = getDayTheWeek(endOfYear, 'last');

  return splitDaysByWeek(startOfTheFirstWeek, endOfTheLastWeek);
};

const getDayTheWeek = (date: Date, direction: 'first' | 'last') => {
  const newDate = getUTCDate(date);
  const asc = direction === 'last';
  const requiredDay = asc ? 0 : 1;

  while (newDate.getUTCDay() !== requiredDay) {
    const day = newDate.getUTCDate();

    newDate.setUTCDate(day + (asc ? 1 : -1));
  }

  return newDate;
};

const splitDaysByWeek = (
  from: Date,
  to: Date,
): [number | undefined, Array<Array<Date>>] => {
  const weeks = [];
  const iteratorDate = getUTCDate(from);
  const today = getUTCDate(new Date());

  const todayDate = {
    date: today.getUTCDate(),
    month: today.getUTCMonth(),
    year: today.getUTCFullYear(),
  };

  let todayWeek;

  const toTime = to.getTime();

  while (iteratorDate.getTime() < toTime) {
    let i = 0;
    const week = [];

    while (i < 7) {
      week.push(getUTCDate(iteratorDate));

      if (
        iteratorDate.getUTCDate() === todayDate.date &&
        iteratorDate.getUTCMonth() === todayDate.month &&
        iteratorDate.getUTCFullYear() === todayDate.year
      ) {
        todayWeek = weeks.length;
      }

      iteratorDate.setUTCDate(iteratorDate.getUTCDate() + 1);
      i++;
    }

    weeks.push(week);
  }

  return [todayWeek, weeks];
};
