export const getWeekNumber = (currentDate: Date, weeks: number) => {
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days =
    Math.ceil(
      (currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000),
    ) +
    weeks * 7;

  const weekNumber = Math.ceil((days + startDate.getDay()) / 7);
  return weekNumber;
};

const getWeek = (date: Date) => {
  date = new Date(+date);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));

  const firstJanuary = new Date(date.getFullYear(), 0, 1);
  const dayNr = Math.ceil(
    (date.getTime() - firstJanuary.getTime()) / (24 * 60 * 60 * 1000),
  );
  const weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);

  return [date.getFullYear(), weekNr];
};

export const weekCountInYear = (year: number) => {
  const month = 11;

  let week;
  let day = 31;

  do {
    const d = new Date(year, month, day--);

    week = getWeek(d)[1];
  } while (week == 1);

  return week;
};

export const getWeekNumberOfDate = (date: Date) => {
  date = new Date(+date);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));

  const firstJanuary = new Date(date.getFullYear(), 0, 1);
  const dayNr = Math.ceil(
    (date.getTime() - firstJanuary.getTime()) / (24 * 60 * 60 * 1000),
  );
  const weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
  return weekNr;
};

export const weeksInYear = (year: number) => {
  const date = new Date(year, 11, 31);
  const week = getWeekNumberOfDate(date);
  return week == 1 ? 52 : week;
};
